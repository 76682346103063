import React from 'react'
// import { Link } from 'gatsby'
import Image from 'gatsby-image'

import style from './vs.module.css'
import image from '../images/vs.png'

const Vs = ({
  name1,
  image1,
  name2,
  image2,
  description1,
  description2,
  weight,
  cat,
  score
}) => (
  <div className={style.gridContainer}>
    <div className={style.vsIcon}>
      <img src={image} alt="Versus" className={style.vsImage} />
    </div>
    <div className={style.weight}>
      {weight}kg
      <br />
      {cat}
    </div>
    <div className={style.player1}>
      <Image fluid={image1} />
      <div className={style.winner}>Winner</div>
      <div className={style.description}>
        <div className={style.name}>{name1}</div>
      </div>

      {/* <p className={style.description}>{description1}</p> */}
    </div>
    <div className={style.player2}>
      <Image fluid={image2} />
      <div className={style.defeated}>Defeated</div>
      <div className={style.description}>
        <div className={style.name}>{name2}</div>
      </div>
      {/* <p className={style.description}>{description2}</p> */}
    </div>
  </div>
)

export default Vs
